/* Global CSS styles */



/* Modals */
.v-card__title {
    @apply bg-gray-200 flex justify-between mb-6
}

.v-card .v-card__title  h2 {
    @apply text-2xl font-bold
}

.v-card h2 {
    @apply text-2xl font-bold
}

.v-card h3 {
    @apply text-xl font-bold
}

.v-card p {
    @apply text-base
}