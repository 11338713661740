/* purgecss start ignore */

.hero-image {
  /* max-width: 1920px; */
  /* background-color: #f2f4f8; */
  position: relative;
  height: 24rem;
  --tw-bg-opacity: 1;
  background-color: rgba(108, 122, 140, var(--tw-bg-opacity))
}

@media (min-width: 768px) {
  .hero-image {
    height: 20rem
  }
}

.hero-image .title {
  /* color: #ffffff; */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.21); */
  /* margin: 0; */
  position: absolute;
  top: 33.333333%;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

/*
.top {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/ss3.jpg');
  background-repeat: no-repeat;
} */

/* purgecss end ignore */
